@import "./variables";

.main-card {
  margin: 24px;
  width: 100%;
}

.loading-spinner-wrapper {
  display: inline-block;
  margin-top: -2px;
}

.no-data {
  text-align: center;
}

span.dialog-title {
  @media (max-width: $sm) {
    font-size: 16px;
  }
}

.search-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-right: 20rem;

  .search {
    color: #1E2129;
    background: $white;
    width: 60rem;
    border-radius: 2.8rem;
    display: flex;
    align-items: center;
    line-height: 4rem;
    height: 5rem;
    margin: 2rem 0;

    .mat-icon {
      height: 5rem;
      line-height: 5rem;
      padding: 0 2rem;
      cursor: pointer;
    }

    #name, #search {
      width: 90%;
    }
  }
}

.paginator-holder {
  .mat-paginator-container {
    justify-content: center;
    width: 100%;
  }
}

.multiline-tooltip {
  white-space: pre-line;
}


.mat-select-panel mat-option.mat-option.full-name-option {
  height: unset;
  border-bottom: 1px dashed $dark-grey;
}

.full-name-option .mat-option-text.mat-option-text {
  white-space: normal;
  line-height: 1.5em;
  padding: 10px 0;
}

.full-name-select .mat-select .mat-select-trigger {
  height: auto;
}

.full-name-select .mat-select .mat-select-value span {
  white-space: normal;
}

mat-form-field {
  max-width: 100%;
}

.add-item {
  text-align: center;
  color: $main-color;

  .add-item-icon {
    margin-right: 4px;
  }
}

.email-redirect:disabled {
  cursor: pointer;
  color:blue !important;
  text-decoration: underline;
}